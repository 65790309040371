import { BREAKPOINTS } from '@core/constants/breakpoints';
import styled from 'styled-components';
import { PageSection } from '../PageSection';

export const Container = styled(PageSection)`
  display: grid;
  grid-template-areas:
    'img'
    'icon'
    'title'
    'desc';
  grid-row-gap: 32px;
  padding-top: 68px;
  padding-bottom: 68px;

  @media ${BREAKPOINTS.tablet} {
    grid-template-areas:
      'icon  img'
      'title img'
      'desc  img';
    grid-template-columns: minmax(auto, 640px) auto;
    grid-template-rows: min-content minmax(0, max-content) 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 16px;
  }
`;

export const Title = styled.h2`
  grid-area: title;
  font-size: 32px;
  line-height: 47px;
`;

export const Description = styled.p`
  grid-area: desc;
  font-size: 24px;
  line-height: 35px;
`;

export const Icon = styled.img`
  grid-area: icon;
`;

export const Banner = styled.img`
  grid-area: img;
  max-width: 80%;
  margin: 0 auto;

  @media ${BREAKPOINTS.tablet} {
    max-width: 100%;
  }
`;
