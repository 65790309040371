import styled from 'styled-components';

import { Button, Modal } from '@core/components';
import { PALETTE } from '@core/constants';

export const StyledModal = styled(Modal)`
  background-color: ${PALETTE.violet};
  color: ${PALETTE.white};
  padding: 32px 22px;
  border-radius: 12px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

export const Form = styled.form`
  display: grid;
  grid-row-gap: 22px;
  overflow-y: auto;
  flex: 1;
`;

export const SubmitButton = styled(Button)`
  height: 48px;
  margin-top: 12px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  max-width: 80%;
  margin-bottom: 24px;
`;
