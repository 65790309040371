import * as yup from 'yup';

const PHONE_REGEX = /^\(?[1-9]{2}\)? ?(?:[2-8]|9 [1-9])[0-9]{3}\-?[0-9]{4}$/;

export const formValidations = yup.object({
  nome: yup.string().required('Favor informar o nome.'),
  empresa: yup.string().required('Favor informar a empresa.'),
  email: yup
    .string()
    .email('Favor informar um e-mail valido.')
    .required('Favor informar o e-mail.'),
  telefone: yup
    .string()
    .matches(PHONE_REGEX, 'Favor informar um telefone válido.')
    .required(),
});
