import {
  Container,
  Title,
  Description,
  MainBanner,
  WannaKnowButton,
} from './styles';

import heroImage from '@assets/images/hero.svg';
import { useContactModal } from '@core/hooks/useContactModal';

export const Hero = () => {
  const { openModal } = useContactModal();

  return (
    <Container>
      <Title>O caminho para vender melhor e fidelizar o cliente</Title>

      <MainBanner src={heroImage} />

      <Description>
        Somos uma <b>#varejotech</b> na área de Pricing e Inteligência de
        Mercado. Nosso objetivo é melhorar a experiência de consumo desde a
        análise e planejamento de vendas até o preço final para o consumidor,
        facilitando a aplicação da estratégia de pricing.
      </Description>

      <WannaKnowButton type="button" onClick={openModal}>
        Quero conhecer
      </WannaKnowButton>
    </Container>
  );
};
