import styled from 'styled-components';
import { PageSection } from '../PageSection';
import { BREAKPOINTS } from '@core/constants';
import { ButtonLink } from '@core/components';

export const StyledPageSection = styled(PageSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  padding-bottom: 68px;

  @media ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const StyledButtonLink = styled(ButtonLink)`
  font-size: 22px;
`;
