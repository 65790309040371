import type { KeyboardEvent } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputText, FormField } from '@core/components';
import { useContactModal } from '@core/hooks';
import { phoneMask } from '@core/utils/masks';

import { Container, Form, StyledModal, SubmitButton, Title } from './styles';
import { formValidations } from './validations';
import { ContactForm } from './types';
import { sendContactForm } from '@core/apis/contact';
import { toast } from 'react-toastify';

export const ContactModal = () => {
  const { open, closeModal } = useContactModal();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ContactForm>({
    resolver: yupResolver(formValidations),
  });

  const handleClose = () => {
    closeModal();
    reset();
  };

  const onSubmit = async (data: ContactForm) => {
    try {
      await sendContactForm(data);

      toast.success('Dados enviados. Entraremos em contato em breve.');
      closeModal();
    } catch (e) {
      toast.error('Erro ao enviar os dados. Tente novamente mais tarde.');
    }
  };

  const handlePhoneInput = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setValue('telefone', phoneMask(value));
  };

  return (
    <StyledModal show={open} onClose={handleClose} width="340px">
      <Container>
        <Title>
          Comparar preços, melhorar margens, vender mais & fidelizar clientes.
        </Title>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField label="Nome*" error={errors?.nome?.message}>
            <InputText {...register('nome', { required: true })} />
          </FormField>

          <FormField label="Empresa*" error={errors?.empresa?.message}>
            <InputText {...register('empresa', { required: true })} />
          </FormField>

          <FormField label="Telefone*" error={errors?.telefone?.message}>
            <InputText
              type="tel"
              {...register('telefone', { required: true })}
              onInput={handlePhoneInput}
            />
          </FormField>

          <FormField label="E-mail*" error={errors?.email?.message}>
            <InputText
              type="email"
              {...register('email', { required: true })}
            />
          </FormField>

          <SubmitButton>Quero conhecer</SubmitButton>
        </Form>
      </Container>
    </StyledModal>
  );
};
