import Head from 'next/head';

import { Footer, Header } from '@core/components';

import { Characteristics } from './Characteristics';
import { Hero } from './Hero';
import { Devices } from './Devices';
import { ContactModal } from './ContactModal';
import { AccountAccess } from './AccountAccess';

export const Home = () => (
  <>
    <Head>
      <title>Sales Sense</title>
    </Head>

    <Header />

    <main>
      <Hero />

      <Characteristics />

      <Devices />

      <ContactModal />

      <AccountAccess />
    </main>

    <Footer />
  </>
);
