import { Container, Title, Banner, Icon, Description } from './styles';
import chartBanner from '@assets/images/charts.svg';
import deviceIcon from '@assets/icons/devices.svg';

export const Devices = () => (
  <Container>
    <Icon src={deviceIcon} alt="" width={56} height={56} />

    <Title>Web, mobile e cada vez mais simples</Title>

    <Description>
      Sistema web centralizado para análise e gestão. App para auxiliar nas
      pesquisas do dia a dia.
    </Description>

    <Banner
      src={chartBanner}
      alt="Ilustração de um homem em frente a diversos gráficos"
    />
  </Container>
);
