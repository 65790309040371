import { Strength } from './Strength';
import { Container, Content } from './styles';

import brainIcon from '@assets/icons/psychology.svg';
import barChartIcon from '@assets/icons/bar-chart.svg';
import wifiIcon from '@assets/icons/wifi.svg';

export const Characteristics = () => (
  <Container>
    <Content>
      <Strength icon={brainIcon} title="Inteligência artificial e real">
        Na nuvem e com o suporte da inteligência artificial da Amazon, os
        insights chegam mais rápidos e precisos para a sua estratégia.
      </Strength>

      <Strength icon={barChartIcon} title="Os dados trazem as respostas">
        Dashboards que entregam mais que gráficos e números. Nosso app responde
        às suas perguntas e ajuda a ter uma visão mais ampla do cenário dos seus
        preços e da concorrência.
      </Strength>

      <Strength icon={wifiIcon} title="Integração com grandes ERPs">
        Ferramenta integrada com grandes ERPs do mercado. Otimizando o tempo de
        início do projeto.
      </Strength>
    </Content>
  </Container>
);
