import { BREAKPOINTS } from '@core/constants/breakpoints';
import styled from 'styled-components';

export const Container = styled.article``;

export const Title = styled.h2`
  max-width: 60%;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  margin: 16px 0 22px;

  @media ${BREAKPOINTS.tablet} {
    max-width: 80%;
    font-size: 32px;
    line-height: 46px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 26px;

  @media ${BREAKPOINTS.tablet} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const Icon = styled.img``;
