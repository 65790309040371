import { ButtonLink } from '@core/components';
import { StyledButtonLink, StyledPageSection } from './styles';

export const AccountAccess = () => {
  return (
    <StyledPageSection>
      <StyledButtonLink
        href={process.env.NEXT_PUBLIC_SIGN_IN_PATH!}
        target="_blank"
        variant="outline"
        external
      >
        Acessar minha conta
      </StyledButtonLink>
    </StyledPageSection>
  );
};
