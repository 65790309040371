import { TopicProps } from './types';
import { Container, Title, Description, Icon } from './styles';

export const Strength = ({ title, icon, children }: TopicProps) => {
  return (
    <Container>
      <Icon src={icon} alt="" width={52} height={52} />
      <Title>{title}</Title>
      <Description>{children}</Description>
    </Container>
  );
};
