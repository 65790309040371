import { BREAKPOINTS } from '@core/constants/breakpoints';
import { PALETTE } from '@core/constants';
import styled from 'styled-components';
import { PageSection } from '../PageSection';

export const Container = styled(PageSection)`
  max-width: 100%;
  background-color: ${PALETTE.hawkesBlue};
`;

export const Content = styled.div`
  display: grid;
  grid-row-gap: 32px;
  max-width: var(--site-width);
  margin: 0 auto;

  @media ${BREAKPOINTS.tablet} {
    grid-template-columns: repeat(auto-fit, 340px);
    justify-content: space-between;
    grid-column-gap: 16px;
    grid-row-gap: unset;
  }
`;
