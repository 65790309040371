import styled from 'styled-components';
import { Button } from '@core/components';
import { BREAKPOINTS } from '@core/constants/breakpoints';
import { PageSection } from '../PageSection';
import { PALETTE } from '@core/constants';

export const Container = styled(PageSection)`
  display: grid;
  grid-template-areas:
    'title'
    'image'
    'desc'
    'button';
  align-items: start;
  grid-row-gap: 24px;

  @media ${BREAKPOINTS.desktop} {
    grid-template-areas:
      'title .'
      'desc  image'
      'button  image';
    grid-template-columns: minmax(0, 640px) auto;
    padding-right: 34px;
  }
`;

export const Title = styled.h1`
  grid-area: title;
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
  color: ${PALETTE.mineShaft};

  @media ${BREAKPOINTS.tablet} {
    font-size: 48px;
    line-height: 64px;
  }
`;

export const Description = styled.p`
  grid-area: desc;
  font-size: 18px;
  line-height: 24px;
  color: ${PALETTE.tundora};

  @media ${BREAKPOINTS.tablet} {
    font-size: 24px;
    line-height: 36px;
    margin: 36px 0 42px;
  }
`;

export const MainBanner = styled.img`
  grid-area: image;
  width: 100%;
  max-width: 80%;
  margin: auto;

  @media ${BREAKPOINTS.tablet} {
    max-width: 100%;
  }

  @media ${BREAKPOINTS.desktop} {
    margin-bottom: -68px;
  }
`;

export const WannaKnowButton = styled(Button)`
  grid-area: button;
  font-size: 24px;
  font-weight: bold;
  align-self: start;
  justify-self: start;
  line-height: 34px;
  width: 100%;

  @media ${BREAKPOINTS.tablet} {
    width: unset;
    min-width: 350px;
    justify-self: center;
  }

  @media ${BREAKPOINTS.desktop} {
    justify-self: start;
  }
`;
